import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import ProjectList from "../components/project-list"
import CustomHelmet from "../components/custom-helmet"

import "./portfolio.scss"

export default function Portfolio({ data }) {
  return (
    <>
      <CustomHelmet title="Projects - Lunta Labs" />
      <Header theme="black" />
      <div className="portfolio">
        <h1>Projects</h1>
        <div className="portfolio__projects">
          <ProjectList projects={data.allContentfulProject.edges} />
        </div>
      </div>
      <Footer />
    </>
  )
}

export const projectQuery = graphql`
  query PortfolioQuery {
    allContentfulProject {
      edges {
        node {
          slug
          title
          thumbnail {
            file {
              url
            }
          }
        }
      }
    }
  }
`
